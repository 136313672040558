import { Component } from 'vue-property-decorator'
import { mapState } from 'vuex';
import GtrSuper from "@/modules/common/components/mixins/gtr-super.mixin";

@Component({
    name: 'GtrOrdersClosed',
    computed: {
        ...mapState('register', ['allContent'])
    }
})
export default class GtrOrdersClosed extends GtrSuper {
    data () {
        return {
            loading: false
        }
    }
    async mounted() {
        this.$data.loading = true
        await this.$store.dispatch('register/getAllContent', {
            event_identifier: this.$route.params.event_identifier,
            reg_type: 'default'
        })
        this.$data.loading = false
    }

    get ordersClosedVerbiage () {
        return (this as any)?.allContent?.data?.orders_settings?.orders_closed_verbiage ?? 'This site is not yet active'
    }
}
